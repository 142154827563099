import { TABLET_BREAKPOINT } from "@util/constants";
import { A, Container } from "@util/standard";
import React from "react";
import styled from "styled-components";

interface Props {
  youtubeEmbedLink?: string;
}

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;

  border: none;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
  }
`;

function YoutubeVideo({ youtubeEmbedLink }: Props) {
  return (
    <Container width="100%" height="100%" minHeight="400px">
      {youtubeEmbedLink && (
        <StyledIframe src={`https://www.youtube.com/embed/${youtubeEmbedLink}?autoplay=1&mute=0`}></StyledIframe>
      )}
    </Container>
  );
}

export default YoutubeVideo;
