import React from "react";
import styled from "styled-components";

import { SanityRecipe } from "@graphql-types";
import { Container, H3 } from "@util/standard";
import { Button } from "@components/global";
import { colors, fontWeights, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityRecipe;
  margin?: string;
}

const TextContainer = styled(Container)`
  width: 55%;
  text-align: center;
  max-width: 600px;
  justify-content: center;
  margin: auto;
  h1,
  h2,
  h3,
  p {
    font-weight: ${fontWeights.normal};
    color: ${colors.darkerGreen};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 80%;
  }
`;

function RelatedRecipe({ data, margin }: Props) {
  if (data == null) {
    return null;
  }

  return (
    <Container flexDirection="column" justifyContent="center" margin={margin ? margin : "100px 0"}>
      <TextContainer>
        <H3 margin="0">Serve this with {data.recipeName}</H3>
      </TextContainer>
      <Container justifyContent="center" margin="30px 0 0 0" tabletWidth="80%" tabletMargin="30px auto 0 auto">
        <Button linkTo={`/recipes/${data.slug?.current}`} theme="block" text={`${data.recipeName} recipe`} />
      </Container>
    </Container>
  );
}

export default RelatedRecipe;
