import React, { useState } from "react";
import styled from "styled-components";

import { Checkbox, Image } from "@components/global";
import { Maybe, SanityMethodStep } from "@graphql-types";
import { Container, H3, P } from "@util/standard";
import { isSanityImage, isSanityRecipeStep } from "@util/types";
import { MOBILE_BREAKPOINT } from "@util/constants";

const StyledH3 = styled(H3)`
  margin: 80px 0 40px 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 50px 0 40px 0;
  }
`;
interface Props {
  data: Maybe<SanityMethodStep>[];
}

interface StepCheckProps {
  step: string;
  hideCheck?: boolean;
}

function Methods({ data }: Props) {
  if (data == null) {
    return null;
  }

  const StepCheck = ({ step, hideCheck }: StepCheckProps) => {
    const [checked, setChecked] = useState(false);

    if (step == null) {
      return null;
    }
    return (
      <Container onClick={() => setChecked(!checked)} margin="0 0 15px 0" cursor="pointer">
        {!hideCheck && <Checkbox checked={checked} isDark padding="0 15px 0 0" />}
        <P margin="5px 5px 0 0 ">{step}</P>
      </Container>
    );
  };

  return (
    <Container width="75%" flexDirection="column" tabletWidth="80%" tabletMargin="auto">
      {data.map(methodGroup => {
        if (methodGroup == null) {
          return null;
        }
        return (
          <div key={methodGroup._key}>
            <StyledH3 fontFamily="proximaNova">
              {methodGroup.title ? methodGroup.title : "Method"}
            </StyledH3>
            <Container flexDirection="column">
              {methodGroup.methods?.map(method => {
                if (isSanityRecipeStep(method)) {
                  return (
                    <Container margin="0 0 40px 0" flexDirection="column" key={method._key}>
                      <P margin="0 0 15px 0">{method.stepName?.toUpperCase()}</P>
                      <Container flexDirection="column">
                        {method.subSteps?.map(step => {
                          if (step == null) {
                            return null;
                          }
                          let keyGen = Math.random().toString(36).substring(7);
                          return (
                            <Container key={keyGen}>
                              <StepCheck step={step} hideCheck={method.hideCheckBoxes} />
                            </Container>
                          );
                        })}
                      </Container>
                    </Container>
                  );
                }
                if (isSanityImage(method)) {
                  return (
                    <Container margin="0 0 40px 0" key={method._key}>
                      <Image image={method.asset?.gatsbyImageData} />
                    </Container>
                  );
                }
                return null;
              })}
            </Container>
          </div>
        );
      })}
    </Container>
  );
}

export default Methods;
