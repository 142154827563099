import React from "react";

import { Button } from "@components/global";
import { SanityProduct } from "@graphql-types";
import { colorsRGB } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, H3 } from "@util/standard";
import { addItemToCart, getProduct } from "@util/shopifyHelper";
import { useStore } from "@state/store";

interface Props {
  data: SanityProduct;
}

function RelatedProduct({ data }: Props) {
  if (data == null) {
    return null;
  }

  const { isLaptopWidth } = useCheckScreenWidth();
  const { checkout } = useStore();

  const handleCartButtonClick = (item: SanityProduct) => {
    if (checkout == null || checkout.id == null) {
      toast.error("There was a problem adding this item to your Cart.");
      return null;
    }
    getProduct(item).then(itemData => {
      if (itemData[0].id == null) {
        toast.error("There was a problem adding this item to your Cart.");
      }

      const productId = itemData[0].variants[0].id.toString();

      addItemToCart(checkout.id, productId, 1);
    });
    return;
  };

  return (
    <Container width="98%" margin="15px auto" backgroundRGBColor={colorsRGB.darkerGreen(0.05)}>
      <Container width="75%" margin="60px auto" flexDirection={isLaptopWidth ? "row" : "column"}>
        <Container width="50%" tabletWidth="100%" justifyContent="center">
          <H3
            margin={isLaptopWidth ? "0" : "0 0 30px 0"}
            color="darkerGreen"
            width="90%"
          >{`Found in "${data.productName}"`}</H3>
        </Container>
        <Container width="50%" tabletWidth="100%" height="50%" margin="auto" justifyContent="center">
          <Button text="Add book to cart" theme="bordered" onClick={() => handleCartButtonClick(data)} />
          <Button text="Shop other products" theme="text" linkTo="/shop" />
        </Container>
      </Container>
    </Container>
  );
}

export default RelatedProduct;
