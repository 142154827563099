import React, { useState } from "react";
import styled from "styled-components";

import { Checkbox } from "@components/global";
import { Maybe, SanityIngredientGroup, SanityRecipe, SanityRecipeIngredient } from "@graphql-types";
import { Container, P, H3, A } from "@util/standard";
import { isSanityRecipe, isSanityRecipeRefWithQuantity } from "@util/types";
import { colors, colorsRGB } from "@util/constants";

interface Props {
  data: Maybe<SanityIngredientGroup>[];
  utensils: Maybe<string[]>;
}

interface IngredientCheckProps {
  ingredient: SanityRecipeIngredient;
}

interface RecipeCheckProps {
  recipe: SanityRecipe;
  recipeQuantity?: string;
  recipeAction?: string;
}

interface UtensilCheckProps {
  utensil: string;
}

const RecipeRef = styled(A)`
  opacity: 1;
  &:hover {
    opacity: 0.6;
    transition: opacity 0.2;
  }

  transition: opacity 0.2;
`;

function IngredientsBar({ data, utensils }: Props) {
  const [utensilsTabOpen, setUtensilsTabOpen] = useState(false);

  const IngredientCheck = ({ ingredient }: IngredientCheckProps) => {
    const [checked, setChecked] = useState(false);

    if (ingredient == null || ingredient.ingredient == null) {
      return null;
    }

    const quantity = ingredient.quantity && ingredient.quantity !== "" ? ingredient.quantity : "";
    const measure = ingredient.measure && ingredient.measure !== "" ? `${ingredient.measure} ` : "";
    const action = ingredient.action && ingredient.action !== "" ? `, ${ingredient.action}` : "";

    return (
      <Container onClick={() => setChecked(!checked)} width="100%" margin="0 0 10px 0" cursor="pointer">
        <Container margin="0 12px 0 0" cursor="pointer">
          <Checkbox checked={checked} />
        </Container>
        {ingredient.ingredient.displayName && (
          <P textDecoration={checked ? "line-through" : ""} margin="5px 5px 0 0 " color="white">
            {`${quantity} ${measure}${ingredient.ingredient?.displayName}${action}`}
          </P>
        )}
      </Container>
    );
  };

  const UtensilCheck = ({ utensil }: UtensilCheckProps) => {
    const [checked, setChecked] = useState(false);

    return (
      <Container onClick={() => setChecked(!checked)} width="100%" margin="0 0 10px 0" cursor="pointer">
        <Container margin="0 12px 0 0" cursor="pointer">
          <Checkbox checked={checked} />
        </Container>
        {utensil && (
          <P textDecoration={checked ? "line-through" : ""} margin="5px 5px 0 0 " color="white">
            {utensil}
          </P>
        )}
      </Container>
    );
  };

  const RecipeCheck = ({ recipe, recipeQuantity, recipeAction }: RecipeCheckProps) => {
    const [checked, setChecked] = useState(false);

    if (recipe == null) {
      return null;
    }

    return (
      <Container width="100%" margin="0 0 10px 0" cursor="pointer">
        <Container margin="0 12px 0 0" cursor="pointer" onClick={() => setChecked(!checked)}>
          <Checkbox checked={checked} />
        </Container>
        {recipe.recipeName && (
          <Container>
            {recipeQuantity && (
              <A cursor="default" textDecoration={checked ? "line-through" : ""} margin="0 5px 0 0" color="white">
                {recipeQuantity}
              </A>
            )}
            <RecipeRef underlined color="white" href={`/recipes/${recipe.slug?.current}`}>
              {recipe.recipeName}
            </RecipeRef>
            {recipeAction && (
              <A cursor="default" textDecoration={checked ? "line-through" : ""} margin="0 5px 0 0" color="white">
                , {recipeAction}
              </A>
            )}
          </Container>
        )}
      </Container>
    );
  };

  return (
    <Container
      width="100%"
      height="calc(100vh - 81px)"
      tabletHeight="100%"
      backgroundColor="mossGreen"
      flexDirection="column"
      overflowY="auto"
    >
      <Container width="65%" tabletWidth="80%" margin="80px auto 35px auto">
        <H3
          color="white"
          opacity={utensilsTabOpen ? 0.5 : 1}
          fontFamily="proximaNova"
          onClick={() => setUtensilsTabOpen(false)}
          cursor={utensils && utensils.length > 0 ? "pointer" : "default"}
        >
          Ingredients
        </H3>
        {utensils && utensils.length > 0 && (
          <H3 color="white" opacity={0.5} margin="auto 10px" fontFamily="proximaNova">
            |
          </H3>
        )}
        {utensils && utensils.length > 0 && (
          <H3
            color="white"
            opacity={utensilsTabOpen ? 1 : 0.5}
            fontFamily="proximaNova"
            onClick={() => setUtensilsTabOpen(true)}
            cursor="pointer"
          >
            Utensils
          </H3>
        )}
      </Container>
      {!utensilsTabOpen ? (
        data.map((group, index) => {
          if (group == null) {
            return null;
          }
          const isLast = index === data.length - 1;
          return (
            <Container flexDirection="column" width="65%" tabletWidth="80%" margin="0 auto" key={group._key}>
              <Container>
                {group.group && group.group !== "Ingredient Group" && <P color="white">{group.group.toUpperCase()}</P>}
              </Container>
              {group?.ingredients && (
                <Container flexDirection="column" margin={isLast ? "0 0 85px 0" : "0 0 25px 0"}>
                  {group?.ingredients.map(ingredient => {
                    if (ingredient == null) {
                      return null;
                    }

                    if (isSanityRecipe(ingredient)) {
                      return <RecipeCheck recipe={ingredient} />;
                    }

                    if (isSanityRecipeRefWithQuantity(ingredient)) {
                      if (ingredient.recipeReference == null) {
                        return null;
                      }
                      return (
                        <RecipeCheck
                          recipe={ingredient.recipeReference}
                          recipeQuantity={ingredient.recipeQuantity ?? undefined}
                          recipeAction={ingredient.recipeAction ?? undefined}
                        />
                      );
                    }

                    return <IngredientCheck ingredient={ingredient} key={ingredient._key} />;
                  })}
                </Container>
              )}
            </Container>
          );
        })
      ) : (
        <Container flexDirection="column" width="65%" tabletWidth="80%" margin="0 auto">
          {utensils &&
            utensils.map((utensil, index) => {
              if (utensil == null || utensil === "") {
                return null;
              }
              return <UtensilCheck utensil={utensil} key={utensil + index} />;
            })}
        </Container>
      )}
    </Container>
  );
}

export default IngredientsBar;
