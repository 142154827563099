import React, { useEffect, useState } from "react";
import { FacebookShareButton, PinterestShareButton, EmailShareButton, TwitterShareButton } from "react-share";
import styled from "styled-components";

import { Container, P } from "@util/standard";
import { icons, TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { isBrowser } from "@util/helper";

interface Props {
  pinterestMedia: string;
  margin?: string;
}

const Icon = styled.img`
  margin-right: 8px;
  cursor: pointer;
  opacity: 1;
  width: 16px;
  height: 16px;
`;

const StyledFacebookShareButton = styled(FacebookShareButton)`
  display: flex;
  flex-direction: row;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledPinterestShareButton = styled(PinterestShareButton)`
  display: flex;
  flex-direction: row;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledEmailShareButton = styled(EmailShareButton)`
  display: flex;
  flex-direction: row;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: 15px;
  }
`;

const StyledTwitterShareButton = styled(TwitterShareButton)`
  display: flex;
  flex-direction: row;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledPrintContainer = styled(Container)`
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: 15px;
  }
`;

function ShareButtons({ pinterestMedia, margin }: Props) {
  const handlePrint = () => {
    if (typeof window !== "undefined") {
      window.print();
    }
  };

  const handleMobileShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Something",
          text: "Hello, please come visit my website",
          url: "www.website.com.br",
        })
        .then(() => {
          console.log("Successfully shared");
        })
        .catch(error => {
          console.error("Something went wrong", error);
        });
    }
  };

  const [popUpVisible, setPopUpVisible] = useState(false);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const { isLaptopWidth } = useCheckScreenWidth();

  useEffect(() => {
    if (isBrowser()) {
      setWindowLoaded(true);
    }
  });

  return (
    <>
      {windowLoaded && isBrowser() && (
        <Container
          width="100%"
          justifyContent={isLaptopWidth ? "space-between" : "normal"}
          margin={margin ? margin : "20px 0"}
        >
          {/* <StyledPrintContainer cursor="pointer" onClick={() => handlePrint()}>
            <Icon src={icons.print} />
            <P fontSize={13} tabletFontSize={13} noMargin>
              Print
            </P>
          </StyledPrintContainer> */}
          <StyledEmailShareButton url={location.href}>
            <Icon src={icons.email} alt="email icon" />
            <P fontSize={13} tabletFontSize={13} noMargin>
              Email
            </P>
          </StyledEmailShareButton>
          {isLaptopWidth && (
            <StyledFacebookShareButton url={location.href as string}>
              <Icon src={icons.facebookNoBorder} alt="facebook icon" />
              <P fontSize={13} tabletFontSize={13} noMargin>
                Share
              </P>
            </StyledFacebookShareButton>
          )}
          {isLaptopWidth && pinterestMedia && (
            <StyledPinterestShareButton url={location.href} media={pinterestMedia}>
              <Icon src={icons.pinterest} alt="Pinterest icon" />
              <P fontSize={13} tabletFontSize={13} noMargin>
                Pin
              </P>
            </StyledPinterestShareButton>
          )}
          {isLaptopWidth && (
            <StyledTwitterShareButton url={location.href}>
              <Icon src={icons.twitter} alt="Twitter icon" />
              <P fontSize={13} tabletFontSize={13} noMargin>
                Tweet
              </P>
            </StyledTwitterShareButton>
          )}
          {!isLaptopWidth && (
            <P fontSize={13} tabletFontSize={13} margin="auto 0" underlined onClick={() => handleMobileShare()}>
              Share
            </P>
          )}
        </Container>
      )}
    </>
  );
}

export default ShareButtons;
