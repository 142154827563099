import React from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";

import IngredientsBar from "@components/recipes/ingredientsBar";
import Methods from "@components/recipes/methods";
import RecipeHero from "@components/recipes/recipeHero";
import RelatedProduct from "@components/recipes/relatedProduct";
import { Maybe, Query, SanityIngredientGroup, SanityMetaImage } from "@graphql-types";
import { TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container } from "@util/standard";
import {
  isSanityColumnContent,
  isSanityLearnCategoryPreview,
  isSanityRecipe,
  isSanityRecipePreview,
  isSanityRecipeRefWithQuantity,
  isSanityVideo,
} from "@util/types";
import ColumnContent from "@components/global/columnContent";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { Video } from "@components/global";
import RecipePreview from "@components/global/recipePreview";
import RelatedRecipe from "@components/recipes/relatedRecipe";
import SEO from "@shared/seo";
import { useOurPicksQuery } from "@src/query";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

const formatRecipeIngredients = (ingredients: Maybe<SanityIngredientGroup>[] | undefined) => {
  if (!ingredients?.length) return [];
  return ingredients
    .map(
      el =>
        el?.ingredients?.map(inner => {
          if (isSanityRecipe(inner)) return inner.recipeName;
          if (isSanityRecipeRefWithQuantity(inner))
            return `${inner.recipeQuantity} ${inner.recipeReference?.recipeName} ${inner.recipeAction}`;
          return [inner?.quantity, inner?.measure, inner?.ingredient?.displayName, inner?.action]
            .filter(Boolean)
            .join(" ");
        }) ?? [],
    )
    .flat(2);
};

const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 66px;
  width: 40%;
  margin-right: 10%;
  margin-left: 15px;
  margin-bottom: 15px;
  height: 100%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-left: 0px;
    position: initial;
    width: 100%;
  }
`;

export default function RecipeInnerTemplate({ data }: Props) {
  const { nodes } = data.allSanityRecipe;

  const recipe = nodes[0];
  const { isLaptopWidth } = useCheckScreenWidth();

  const allSanityOurPicks = useOurPicksQuery();
  let hasRelatedRecipes = false;

  const schemaData = {
    "@context": "https://schema.org/",
    "@type": "Recipe",
    name: recipe.recipeName,
    image:
      recipe?.seo?.ogImage?.asset?.url || recipe.featureImage?.asset?.url
        ? [recipe?.seo?.ogImage?.asset?.url || recipe.featureImage?.asset?.url]
        : undefined,
    datePublished: recipe._createdAt ? recipe._createdAt.split("T")[0] : undefined,
    description: recipe.description,
    prepTime: recipe.prepTime ? `PT${recipe.prepTime}M` : undefined,
    cookTime: recipe.cookTime ? `PT${recipe.cookTime}M` : undefined,
    recipeYield: recipe.servingSize,
    recipeIngredient: formatRecipeIngredients(recipe.ingredients ?? []),
  };

  if (recipe == null) {
    return null;
  }

  return (
    <>
      <SEO
        seoData={recipe.seo}
        recipe
        overwriteTitle={recipe.seo?.pageTitle ? undefined : recipe.recipeName}
        overwriteDescription={recipe.seo?.pageDescription ? undefined : recipe.description}
        overwriteImage={recipe.seo?.ogImage?.asset?.url ? undefined : recipe.featureImage?.asset?.url}
        slug={`recipes/${recipe.slug?.current}`}
      >
        <script type="application/ld+json">{JSON.stringify(schemaData, null, 2)}</script>
      </SEO>
      <Layout>
        <RecipeHero recipe={recipe} />
        {recipe.productRef && <RelatedProduct data={recipe.productRef} />}
        <Container width="100%" flexDirection={isLaptopWidth ? "row" : "column"}>
          <StickyContainer>
            {recipe.ingredients && <IngredientsBar data={recipe.ingredients} utensils={recipe.utensils} />}
          </StickyContainer>
          <Container width="50%" tabletWidth="100%">
            {recipe.methods && <Methods data={recipe.methods} />}
          </Container>
        </Container>
        {recipe.suggestedRecipe && <RelatedRecipe data={recipe.suggestedRecipe} />}
        <div>
          {recipe.pageContent &&
            recipe.pageContent.map(data => {
              if (data == null) {
                return null;
              }
              if (isSanityVideo(data)) {
                return (
                  <Container
                    position="relative"
                    height="550px"
                    tabletHeight="400px"
                    width="100%"
                    margin="120px auto"
                    tabletMargin="80px auto"
                    zIndex={5}
                    key={data._key}
                  >
                    <Video
                      url={data.url as string}
                      id={data.url as string}
                      useThumbnail
                      thumbnail={data.image as SanityMetaImage}
                      controls
                      volume
                    />
                  </Container>
                );
              }

              if (isSanityColumnContent(data)) {
                return <ColumnContent data={data} />;
              }

              if (isSanityRecipePreview(data)) {
                hasRelatedRecipes = true;
                return <RecipePreview data={data} />;
              }

              if (isSanityLearnCategoryPreview(data)) {
                return <LearnCategoryPreview data={data} />;
              }
              return null;
            })}

          {/* Add default recipes block if no related recipes are found */}
          {!hasRelatedRecipes && allSanityOurPicks && <RecipePreview data={allSanityOurPicks} />}
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query recipeQuery($slug: String) {
    allSanityRecipe(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityRecipe
      }
    }
  }
`;
